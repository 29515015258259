.register_container {
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 20px;
  box-shadow: 0px 0px 20px grey;
  background: #fff;
}

.register_containerThank {
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 20px;
  box-shadow: 0px 0px 20px grey;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  text-align: center;
}

.text_description {
  margin-left: 30px;
}

.phone_pass {
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
}

.cap_cha {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
}

.view_register {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.td {
  position: absolute;
  right: 1%;
  top: 20%;
  z-index: 10;
}

.btn_register {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

/* mobile */
@media screen and (max-width: 576px) {
  .btn_register {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .btn_register {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
  .register_container {
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #999999;
    padding: 20px;
    box-shadow: 0px 0px 20px grey;
    background: #fff;
  }
}

.login_container {
  width: 300px;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: white;
  padding: 30px;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.submit_login {
  align-items: center;
  justify-content: center;
  place-items: center;
}
.img {
  max-width: 100%;
  width: 7rem;
  height: 7rem;
}
.centerText {
  text-align: center;
}
.test {
  text-align: center;
  margin-bottom: 20px;
}

.installer_container {
  height: 100vh;
  /* background-color: red; */
}
.bold_text {
  font-weight: bold;
  margin-bottom: 10px;
}
.customButton{
  color: "#B50829";
  background-color: "#B50829";
  border: "none";
}
.ip1, .ip2, .ip3 {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ip1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ip3 {
    margin-left: 30px;
}
.changdel {
    color: #b50829;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    width: 5%;
}
.ip1{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border-bottom: 1px solid #ccc; */
}
.ip2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border-bottom: 1px solid #ccc; */
}
.title_component_top {
    font-size: 36px;
    font-weight: 700;
    color: black;
}
.title_component_bot {
    color: #b50829;
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    margin-bottom: 16px;
}
.Accordion {
    border: 1px solid #ccc;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 600px) {
  .organization_container {
      padding: 8px;
  }
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
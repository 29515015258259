.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding: 30px;
}

@media screen and (max-width: 576px) {
  .sidebar {
    display: none;
  }
  .layout__content {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .topnav__right-user__name {
    display: none;
  }
  .topnav__search {
    display: none !important;
  }
  .layout__content-main {
    padding: 0px;
    display: flex;
    height: auto;
  }
}

.datePicker_container {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 3px;
  /* background-color: red; */
}

@media screen and (max-width: 576px) {
  .datePicker_container {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 3px;
    width: 100%;
  }
}

.organization_bold_text {
    font-weight: bold;
    color: red;
  }
  
  .optional_container {
    /* flex-direction: row; */
    /* display: flex; */
    margin-top: 10px;
    /* background-color: red; */
  }
  
  .button_container {
    /* flex-direction: column; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* margin-top: 20px; */
    /* margin-left: 30px; */
    background-color: red;
  }
  
  .type_container {
    /* flex-direction: column; */
    /* display: flex; */
    /* width: auto; */
    /* background-color: red; */
  }
  
  .border_organization {
    border-bottom: 1px solid rgba(0, 0, 0, 0.28);
  }
  
  
.colo_red {
  color: #b50829;
}
.title_component {
  font-size: 36px;
  font-weight: 700;
  color: black;
}
.content_top {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.content,
.content_number,
.content__member {
  font-size: 18px;
  color: #000;
  margin-left: 8px;
}
.member_table {
  margin-top: 16px;
}
.content_member {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.content_excel {
  color: #b50829;
}
.content__top {
  margin-top: 0px;
}
@media only screen and (max-width: 600px) {
  .content_member {
    width: 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.PDFpayment_container {
  /* border-radius: 10px; */
  /* border: 1px solid #999999; */
  padding: 20px;
  /* box-shadow: 0px 0px 20px grey; */
  background: #fff;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.sidebar__logo > img {
  height: 68px;
  width: 68px;
}

.company_name_size {
  font-size: 1.1rem;
  text-align: end;
  width: 100%;
}

.normal_size {
  font-size: 0.9rem;
  width: 100%;
  text-align: end;
}

.body_title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}

.body_context {
  padding: 50px;
}

.bold_text {
  font-weight: bold;
}

.border_table {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.border_cell {
  border-bottom: 1px solid #ccc;
}

/* mobile */
@media screen and (max-width: 576px) {
  .btn_PDFpayment {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .btn_PDFpayment {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
  .PDFpayment_container {
    margin-top: 20px;
    /* border-radius: 10px; */
    /* border: 1px solid #999999; */
    padding: 20px;
    box-shadow: 0px 0px 20px grey;
    background: #fff;
  }
}

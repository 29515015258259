.profile_header {
  display: flex;
  flex-direction: column;
}
.profile_content {
  margin-top: 16px;
}

.title_header {
  font-weight: 700;
  font-size: 45px;
}

.change_password {
  color: #B50829;
  text-align: right;
  font-size: 20px;
  font-weight: 700;
}
.input_content {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.input_content2 {
  display: flex;
  flex-direction: column;
  /* width: 48%; */
}

.data_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px;
}
.data_content2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px;
}
.name_content {
  color: #000;
  font-weight: bold;
  /* font-size: 14px; */
}

.value_content {
  border: 1px solid #ccc;
  /* color: #EBEDF0; */
  height: 28px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 5px;
}
.checkbox_profile {
  margin: 16px;
}
.check_parner {
  font-weight: 500;
  color: #000;
}
.checkbox {
  background-color: #B50829;
  color: #B50829;
  margin-right: 8px;
}

.select_partner {
  display: flex;
  flex-direction: column;
}
.select_partner_bot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.select_partnerbot {
  width: 30%;
  display: flex;
  flex-direction: column;

}
.select_role {
  width: 100%;
}
.select_partnerbot2 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.profile_content {
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
.select_partner, .select_partner_bot {
  margin: 16px;
}
.select_name {
  color: #000;
  font-weight: 700;
  /* font-size: 14px; */
  margin-bottom: 16px;
}
.input_submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.star {
  color: #B50829;
}
.input_submit p {
  width: 179px;
  height: 41px;
  background-color: #B50829;
  color: #fff;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
}
.partner, .select_role, .state_agencies {
  height: 32px;
}
.input_submit:hover {
  cursor: pointer;
}

.username_content {
  /* font-size: 14px; */
  font-weight: 700;
  color: #000;
  /* margin-left: 16px; */
  display: flex;
  flex-direction: column;
}
.value_username {
  border: 1px solid #ccc;
  /* color: #EBEDF0; */
  height: 28px;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  /* margin-left: 16px; */
    width: 100%;
    /* width: 98%; */
  padding-left: 5px;
}

/* reponsive */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .input_content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile_container {
    width: 100%;
    padding: 8px;
  }
  .data_content,.data_content2,.select_partner_bot {
    display: flex;
    flex-direction: column;
  }
  .value_content, .name_content {
    width: 100%;
  }
  .partner, .select_role, .state_agencies {
    /* width: 300px; */
    font-size: 16px;
  }
  .select_partner_bot {
    align-items: stretch;
  }
  .value_username {
    width: 100%;
    /* width: 89%; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .profile_container {
    width: 100%;
    padding: 8px;
  }
  .input_content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .data_content,.data_content2,.select_partner_bot {
    display: flex;
    flex-direction: column;
  }
  .value_content, .name_content {
    width: 100%;
    /* width: 320px; */
  }
  .partner, .select_role, .state_agencies {
    width: 100%;
    /* width: 320px; */
    /* font-size: 16px; */
  }
  .select_partner_bot {
    align-items: stretch;
  }
  .value_username {
    /* width: 86%; */
    width: 100%;
  }
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .value_username {
    width: 100%;
  }
  .input_content2 {
    display: flex;
    flex-direction: column;
    width: 48%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {

} */
.search__workers {
    display: flex;
    margin-bottom: 12px;
}
.choice {
    margin-left: 20px;
    font-size: 16px;
    width: 150px;
    height: 38px;
    text-align: center;
    border: 1px solid #ccc;
    font-weight: 600;
}
h2 {
    color: black;
    font-weight: 600;
    margin-bottom: 12px;
}
.input_search__workers {
    border: 1px solid #ccc;
    height: 38px;
    font-size: 20px;
    padding-left: 8px;
}
.Submit__workers {
    margin-left: 20px;
    background-color: #b50829;
    color: #fff;
    width: 150px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 4px ;
}
.table {
    border: 1px solid;
    margin-block-end: unset;
}
.table th {
    border: solid 1px #000;
    text-align: center;
}
.table td {
    border: #000 1px solid;
    align-items: center;
    text-align: center;
}
/* * img {
    width: 50px;
} */

.view {
    background-color: #223345;
    height: 50px;
    border-radius: 3px;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
}
@media only screen and (max-width: 600px) {
    .search__workers {
        display: grid;
        padding-left: 8px;
    }
    .input_search__workers {
        width: 250px;
        margin-left: 20px;
        margin-bottom: 8px;
    }
    .choice{
        margin-bottom: 8px;
    }
}
  
  /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .search__workers {
        display: grid;
        padding-left: 8px;
    }
    .input_search__workers {
        width: 350px;
        margin-left: 20px;
        margin-bottom: 8px;
    }
    .choice{
        margin-bottom: 8px;
    }
    
}
  
  /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}
.lds-ring {
  display: flex;
  position: relative;
  /* width: auto;
  height: auto; */
  background-color: green;
}
.lds-ring div {
  /* box-sizing: border-box; */
  /* display: block; */
  position: absolute;
  width: 22px;
  height: 22px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom_button_container {
  /* display: flex; */
  /* place-items: center; */
  /* justify-content: center; */
  width: "auto";
  padding: 2px;
  /* margin-left: 20px; */
}
@media screen and (max-width: 576px) {
  .custom_button_container {
    /* justify-content: center; */
    width: "100%";
    padding: 2px;
  }
}

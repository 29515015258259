.copy_option {
    display: inline;
    background-color: red;
  }
  
  /* mobile */
  @media screen and (max-width: 576px) {
    .copy_option {
      display: none;
    }
  }
  @media screen and (min-width: 992px) {
    .copy_option {
      display: inline;
      background-color: red;
    }
  }
  
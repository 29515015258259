h1 {
  color: #000;
  font-weight: 700;
}
.changpass_username {
  margin-left: 20px;
  margin-top: 40px;
  font-size: 20px;
  color: #000;
  border-bottom: 1px solid #ccc;
  line-height: 40px;
}
.title_username {
  margin-left: 12px;
}
.username {
  margin-left: 12px;
}
.changePass_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title_content{
  width: 90%;
  border: 1px solid #ccc;
  margin-top: 32px;
  border-radius: 4px;
  height: 600px;  
}
.title__content {
  background: linear-gradient(0deg, #000 rgba(224, 220, 220, 0.03), rgba(0, 0, 0, 0.03)), #ccc;
  color: #000;
  font-weight: 700;
  line-height: 40px;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 20px;
}
.changepass_input {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}
.changepass__input {
  display: flex;
  justify-content: space-around;
  /* padding-left: 200px; */
  /* padding-right: 200px; */
}
.input_password {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 250px;
  margin-top: 12px;
  height: 36px;
}
.title_input {
  color: #000;
  font-weight: 700;
}
.valid_password {
  color: red;
  font-size: 12px;
}
.bx-show {
  position: absolute;
  right: 0;
  font-size: 36px;
  margin-top: 12px;
}
.changepassword_submit {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.text_submit {
  width: 179px;
  height: 41px;
  background-color: #B50829;
  color: #fff;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
}
.text_submit:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .ChangePassword_container {
    width: 100%;
  }
  h1 {
    margin-left: 20px;
  }
  .title_content {
    display: flex;
    flex-direction: column;
  }
  .changepass__input {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    margin-left: 20px;
  }
  .input_password {
    width: 300px;
  }
  .bx-show {
    padding-right: 36px;
  }
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ChangePassword_container {
    width: 100%;
  }
  h1 {
    margin-left: 20px;
  }
  .title_content {
    display: flex;
    flex-direction: column;
  }
  .changepass__input {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    margin-left: 20px;
  }
  .input_password {
    width: 330px;
  }
  .bx-show {
    padding-right: 36px;
  }
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .ChangePassword_container {
    width: 100%;
    margin-left: 40px;
  }
  .changePass_content {
    width: 90%;
  }
  h1 {
    margin-left: 20px;
  }
  .title_content {
    display: flex;
    flex-direction: column;
  }
  .changepass__input {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    margin-left: 20px;
  }
  .input_password {
    width: 460px;
    height: 60px;
    font-size: 40px;
  }
  .bx-show {
    padding-right: 36px;
  }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .title_content {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
}
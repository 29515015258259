.sidebar {
  min-width: var(--sidebar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #223345;
  box-shadow: var(--box-shadow);
}

.sidebar__logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}
.sidebar__logo {
  margin-left: 16px;
  margin-right: 16px;
}
.logo_company {
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  margin-left: 8px;
}
.sidebar__logo > img {
  height: 68px;
  width: 68px;
}

.sidebar__item {
  /* padding: 0 20px; */
}

.sidebar__item-inner {
  padding: 15px 25px;
  display: flex;
  flex-direction: row;
  transition: color 0.3s ease 0s;
  color: #fff;
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
  margin-left: 20px;
  font-size: 20px;
  
}

.sidebar__item-inner:hover {
  color: var(--main-color);
}

.sidebar__item-inner.active {
  /* border-radius: var(--border-radius); */
  background-image: linear-gradient(
    to right,
    #B50829,
    #B50829
  );
  color: var(--txt-white);
}
.sidebar__group p {
  color: #fff;
  opacity: 0.75;
  text-transform: uppercase;
  border-top: 1px solid rgba(204, 204, 204, 0.6)
  ;
  font-weight: 700;
  line-height: 32px;
  padding-left: 4px;
  padding-top: 4px;
}
.sidebar__group {
  padding-right: 16px;
  padding-left: 16px;
}
a {
  text-decoration: none !important;
}
@media only screen and (max-width: 600px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  
}